import React, { Component } from 'react'
// import { Container} from 'react-bootstrap'
import {Link } from 'react-router-dom'
import './Header.css';
import classnames from 'classnames';



export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hamburger : false,
            prevScroll: window.pageYOffset,
            navFixed: false
        }
    }
        
    toggleHamburgerMenu = () => {
    const dropper = this.state.hamburger;
        this.setState({hamburger : !dropper})
    }

    handleScroll = () => {
        const  prevScrollPos  = this.state.prevScroll;

        const currentScrollPos = window.pageYOffset;
        const navFixed = prevScrollPos < currentScrollPos;

        this.setState({
            prevScrollPos: currentScrollPos,
            navFixed
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }


    render() {
        return (
            
            <>
                <div className={classnames("navabar-wrapper", {'hide-logo': !this.state.navFixed})}>
                
                            <div className='menu-bar'>
                                <div className='menu-bar-row'>
                                    <div className='logo'>
                                        <Link to={'/'} className='mobile-version-logo'>
                                            <div >
                                            <img src='/images/HomeScreenImages/pc_mob.svg' alt='mobile-logo' className='mobile-logo'/>
                                            </div>
                                        </Link> 
                                        <Link to={'/'} className='web-version-logo'>
                                        <img src='/images/HomeScreenImages/pc_web.svg' alt='web-logo' className='web-logo'/><span className='tagline design'>DESIGN STUDIO</span>


                                            {/* <div className='title preciso'>PRECISO</div><span className='subtitle concepts'>CONCEPTS</span><br/>
                                            <span className='tagline design'>DESIGN STUDIO</span> */}
                                        </Link> 
                                    </div>
                                    <div className=''>
                                            <img src='/images/HomeScreenImages/hamburger.svg' alt='hamburger-img' onClick={this.toggleHamburgerMenu}  className='menu-icon'/>
                                    </div>
                                </div>
                                {/* <nav className={this.toggleHamburgerMenu ? 'menu-bar-active active' : 'navbar' }></nav> */}
                                    { 
                                    this.state.hamburger ?
                                            <ul className='menu-bar-active active' onClick={this.toggleHamburgerMenu}>
                                                <li className='hamburger-close' ><img src='/images/HomeScreenImages/times.svg' onClick={this.toggleHamburgerMenu} alt='hamburger-close' className='hamburger-close' /></li>
                                                <li className='about-link'><Link to={'/AboutUs'}>ABOUT US</Link></li>
                                                <li className='project-link'><Link to={'/Project'}>PROJECT</Link></li>
                                                
                                                <li className='brand-link main-link'><Link to={'/BrandDesign'} style={{color:"#A6C37F"}}>BRAND DESIGN</Link></li>
                                                <li className='main-link'><Link to={'/CommunicationDesign'}>COMMUNICATION DESIGN</Link></li>
                                                <li className='main-link'><Link to={'/DigitalDesign'}>DIGITAL DESIGN</Link></li>
                                                <li className='main-link'><Link to={'/EventsDesign'}>EVENTS DESIGN</Link></li>
                                                <li className='main-link'><Link to={'/AdsDesign'}>ADS DESIGN</Link></li>
                                                <li className='main-link'><Link to={'/PackageDesign'}>PACKAGING DESIGN</Link></li>
                                                <li className='main-link'><Link to='/3D'>3D</Link></li>

                                                <li className='contact-link'><a href='/ContactForm'>CONTACT US</a></li>
                                                <li className='follow-link'><a href='/#FollowuS' >FOLLOW US</a></li>
                                            </ul>:null 
                                    }
                            </div>
                </div>
            </>
        )
    }
}
