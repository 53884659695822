import React, { Component } from 'react'
import './BrandDesign.css';
import {Container} from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
// import {IconContext} from 'react-icons'
// import * as VsIcon from 'react-icons/vsc'
import { HashLink as Link } from "react-router-hash-link";

 

export default class Logo extends Component {
    render() {
        return (
            <Container>
                            <div className='brand-design-container'>
                                <div className='brand-design-wrap  brand-design-col-one'>
                                    <div className='brand-design-page-grid'>
                                    <Link to={'/#brnd'}><img src='/images/BrandDesign/brand-design-menu.svg' alt='brand-design-menu' className='brand-design-menu mr-3'/></Link><div className='brand-design-menu'></div>
                                    </div>
                                    <div className='brand-design-menu-title pt-3' style={{}}>
                                    {/* <IconContext.Provider value={{className:"arrow-left brand-design-arrow"}}>
                                                <Link to={'/BrandDesign'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider> */}
                                            Logo
                                    </div>
                                    <div className='brand-design-description py-4'>
                                    A well thought out logo and tagline,
                                     representing the brand’s aesthetic and 
                                     ethos through revolutionary design.
                                    </div>
                                    <div className='brand-design-menu-wrap mt-3'>
                                        <div className='brand-design-menu-link pr-3' style={{textAlign:"right",borderRight:"1px solid #A6C37F"}}>
                                            <Link to={'/Name/'}>NAME </Link>
                                            <br/>
                                            <Link to={'/Identity/'}>IDENTITY</Link>
                                            <br/>
                                            <Link to={'/ReBranding/'}>RE-BRANDING</Link>
                                        </div>
                                        <div className='brand-design-menu-link-right pl-3' style={{textAlign:"left"}}>
                                            <Link to={'/Logo/'}>LOGO</Link>
                                            <br/>
                                            <Link to={'/VisualDesign/'}>VISUAL DESIGN</Link>
                                            <br/>
                                            <Link to={'/Story/'}>STORY</Link>
                                        </div>
                                    </div>
                                </div>
                                    <div className='brand-design-wrap '>
                                        {/* <img src='/images/BrandDesign/rebranding-image.svg' alt='name-menu-img' className='brand-design-menu-image web-brand-logo'/> */}
                                        <div className='desktop-design-carousel'>
                                                    <Carousel>
                                                        <Carousel.Item interval={1000}>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <Link to='/InviteOne'><img src='/images/ClientsLogo/artnam.jpg' alt='' className='menu-designs-grid-image-link'/></Link>
                                                                <img src='/images/ClientsLogo/art.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/artnam.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/ClientsLogo/avo.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/frr.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/quarto.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>                                                <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/ClientsLogo/roling.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/klite.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/mf.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                        </Carousel.Item>
                                                        <Carousel.Item interval={1000}>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <Link to='/InviteOne'><img src='/images/ClientsLogo/artnam.jpg' alt='' className='menu-designs-grid-image-link'/></Link>
                                                                <img src='/images/ClientsLogo/art.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/artnam.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/ClientsLogo/avo.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/frr.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/quarto.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>                                                <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/ClientsLogo/roling.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/klite.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/ClientsLogo/mf.jpg' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                        </Carousel.Item>
                                                    </Carousel>
                                                </div>
                                        <div className='mobile-project-container mobile-brand-logo-container' style={{display:"flex"}}>
                                            <div style={{display:"flex",flexDirection:"column"}} className='mobile-brand-design-logo'>
                                                <img src='/images/ClientsLogo/art.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/artnam.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/avo.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/frr.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/quarto.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/roling.jpg' className='mob-project-img' alt='art-attire'/>
                                            </div>
                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                <img src='/images/ClientsLogo/klite.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/kobelco.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/mf.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/s2capital.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/satnam.jpg' className='mob-project-img' alt='art-attire'/>
                                                <img src='/images/ClientsLogo/mint.jpg' className='mob-project-img' alt='art-attire'/>
                                            </div>
                                        </div>
                                    </div>
                                        
                            </div>
                        </Container>
        )
    }
}
