import React, { Component } from 'react'
import { Container } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import './BrandDesign.css';
import { HashLink as Link } from "react-router-hash-link";


export default class BrandDesign extends Component {
    render() {
        return (
                        <Container>
                            <div className='brand-design-container'>
                                <div className='brand-design-wrap  brand-design-col-one'>
                                    <div className='brand-design-page-grid'>
                                        <Link to={'/#brnd'}><img src='/images/BrandDesign/brand-design-menu.svg' alt='brand-design-menu' className='brand-design-menu mr-3'/></Link><div className='brand-design-menu'></div>
                                    </div>
                                    
                                    <div className='brand-design-description py-4'>
                                        Starting with just an idea ? We carefully select,
                                        impactful yet meaningful names for your brand.
                                        Establishing an identity for an idea.
                                    </div>
                                    <div className='brand-design-menu-wrap mt-3'>
                                        <div className='brand-design-menu-link pr-3' style={{textAlign:"right",borderRight:"1px solid #A6C37F"}}>
                                            <Link to={'/Name'}>NAME</Link>
                                            <br/>
                                            <Link to={'/Identity'}>IDENTITY</Link>
                                            <br/>
                                            <Link to={'/ReBranding/'}>RE-BRANDING</Link>
                                        </div>
                                        <div className='brand-design-menu-link-right pl-3' style={{textAlign:"left"}}>
                                            <Link to={'/Logo/'}>LOGO</Link>
                                            <br/>
                                            <Link to={'/VisualDesign/'}>VISUAL DESIGN</Link>
                                            <br/>
                                            <Link to={'Story'}>STORY</Link>
                                        </div>
                                    </div>
                                </div>
                                    <div className='brand-design-wrap'>
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="brand-design-carousel-image"
                                                src="/images/BrandDesign/brand-design-carousel-1.jpg"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/BrandDesign/brand-design-carousel-2.png"
                                                className="brand-design-carousel-image"
                                                alt="second slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/BrandDesign/brand-design-carousel-3.png"
                                                className="brand-design-carousel-image"
                                                alt="third slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                            </div>
                        </Container>
        )
    }
}
