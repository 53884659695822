import React, { Component } from 'react'
import './ContactForm.css';
import { Container } from "react-bootstrap";
// import { IconContext } from 'react-icons'
// import {IoCallOutline} from "react-icons/io5";
// import { GoMail } from "react-icons/go";
export default class ContactForm extends Component {
    render() {
        return (
            <>
                <Container>
                    <div className="page-container abc" >
                    <div className='contact-form-container' >
                        <form className='contact-form'>
                            <div className='contact-form-wrap'>
                                <div className='contact-form-title'>Let's get in touch</div>
                                <input type='text' placeholder='Name' name='fname' autoComplete="off" className='form-input'  required></input>
                                <input type='text' placeholder='Mobile' name='mobile' autoComplete="off" className='form-input'  required></input>
                                <input type='text' placeholder='Email' name='fname' autoComplete="off" className='form-input '  required></input>
                                <input type='text' placeholder='Let us know what you think' name='fname' autoComplete="off" className='form-input input-last' required></input>
                                <button className='send-button' >send</button>
                                {/* onClick={} */}
                            </div>
                            <div className='contact-form-wrap page-container'>
                                <div className='checkbox-container'>
                                <div className='contact-form-title'>Interested In?</div>
                                    <div className='checkbox-wrap'>
                                        <div className='checkbox-item brand-checkbox'>Brand Designing<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item communication-checkbox'>COMMUNICATION Design<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item digital-checkbox'>digital  marketing<input type='checkbox' name='' className='check-box'/></div>
                                    </div>
                                    <div className='checkbox-wrap'>
                                        <div className='checkbox-item events-checkbox'>events Designs<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item product-checkbox'>product Design<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item packaging-checkbox'>packaging <input type='checkbox' name='' className='check-box'/></div>
                                    </div>
                            </div>
                            </div>
                        </form>
                    </div>
                    </div>
                    <div className='mobile-contact-form' style={{display:"flex",justifyContent:"center",flexDirection:"column",height:"100vh",backgroundColor:"#fffff"}}>
                        <form className='mobile-contact-form-wrap' >
                            <div className='mob-cont-title'>Let's get in touch</div>
                            <input type='text' placeholder='NAME' name='fname' autoComplete='off' required className='mobile-contact-form-input'/>
                            <input type='text' placeholder='mobile' name='mobile' autoComplete='off' required className='mobile-contact-form-input'/>
                            <input type='text' placeholder='email' name='mobile' autoComplete='off' required className='mobile-contact-form-input '/>
                            <div ><div className='mob-cont-title' >Interested in ?</div>
                            <div className='checkbox-wrap'>
                                        <div className='checkbox-item brand-checkbox'>Brand<br/> Designing<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item communication-checkbox'>COMMUNICATION Design<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item digital-checkbox'>digital <br/> marketing<input type='checkbox' name='' className='check-box'/></div>
                                    </div>
                                    <div className='checkbox-wrap'>
                                        <div className='checkbox-item events-checkbox'>events <br/>Designs<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item product-checkbox'>product <br/>Design<input type='checkbox' name='' className='check-box'/></div>
                                        <div className='checkbox-item packaging-checkbox'>packaging <input type='checkbox' name='' className='check-box'/></div>
                                    </div>
                            </div>

                            <span className='mobile-contact-form-input' style={{border:"none",fontSize:"14px",fontFamily:"roboto",textTransform:"none"}}>Let us know what you think</span>
                            <input type='text' placeholder='' name='mobile' autoComplete='off' required className='mobile-contact-form-input let-us-know'/>
                            <input type='text' placeholder='' name='mobile' autoComplete='off' required className='mobile-contact-form-input let-us-know'/>
                            <input type='text' placeholder='' name='mobile' autoComplete='off' required className='mobile-contact-form-input let-us-know'/>
                            <button className='mobile-send-button' >send</button>
                            {/* onClick={4} */}
                        </form>  
                        {/* <div className='mobile-home-contact-wrap' style={{display:"flex",flexDirection:"column"}}>
                            {/* <div>
                                <IconContext.Provider value={{className:"icons"}}>
                                <span className='contact-det num-det ml-3' style={{letterSpacing:"3.5px"}}><IoCallOutline/>+91 9040475000</span>
                                </IconContext.Provider>
                            </div>
                            <div>
                                <IconContext.Provider value={{className:"icons"}}>
                                <span className='email-det ml-3' ><GoMail/>info@precisoconcepts.com</span>
                                </IconContext.Provider>
                            </div> 
                                
                        </div> */}
                            {/* <img src='/images/MobileHomeScreen/contact-mob.png' alt='cont-det' className='mobile-contact-detail-img'/> */}
                    </div>
                </Container>
            </>
        )
    }
}
