import React, { Component } from 'react'
import './MenuDesigns.css';
import Carousel from 'react-bootstrap/Carousel';
import { HashLink as Link } from "react-router-hash-link";
// import {IconContext} from 'react-icons';
// import * as VsIcon from 'react-icons/vsc';


export default class Invites extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Digital-Deisgn'}><img src='/images/EventsDesign/events.svg' alt='digital-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-link-title pt-3' style={{}}>
                                    {/* <IconContext.Provider value={{className:"arrow-left menu-designs-left-arrow"}}>
                                                <Link to={'/EventsDesign'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider> */}
                                            invites
                                    </div>
                    <div className='menu-designs-description py-4'>
                            Digital and print luxurious invites to excite 
                            your guests from step one. A grand event calls for 
                            a grand invitation.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Concepts'}>Concepts</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Invites'}>Invites</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Design'}>design</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/Planning'}>planning</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/3D'}>3D</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                                                <div className='desktop-design-carousel'>
                                                    <Carousel>
                                                        <Carousel.Item interval={1000}>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <Link to='/InviteOne'><img src='/images/EventsInvite/invite/invite1.png' alt='' className='menu-designs-grid-image-link'/></Link>
                                                                <img src='/images/EventsInvite/invite/invite2.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite3.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/EventsInvite/invite/invite4.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite5.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite6.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>                                                <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/EventsInvite/invite/invite7.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite8.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite9.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                        </Carousel.Item>
                                                        <Carousel.Item interval={1000}>
                                                        <div className='menu-designs-grid-carousel'>
                                                                <Link to='/InviteOne'><img src='/images/EventsInvite/invite/invite1.png' alt='' className='menu-designs-grid-image-link'/></Link>
                                                                <img src='/images/EventsInvite/invite/invite2.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite3.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                            <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/EventsInvite/invite/invite4.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite5.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite6.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>                                                <div className='menu-designs-grid-carousel'>
                                                                <img src='/images/EventsInvite/invite/invite7.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite8.png' alt='' className='menu-designs-grid-image-link'/>
                                                                <img src='/images/EventsInvite/invite/invite9.png' alt='' className='menu-designs-grid-image-link'/>
                                                            </div>
                                                        </Carousel.Item>
                                                    </Carousel>
                                                </div>
                                            <div className='mobile-design-carousel'>
                                                <div className='mobile-designs-row'>
                                                    <Link to='/InviteOne'><img src='/images/EventsInvite/invite/invite1.png' alt='' className='mobile-menu-designs-grid-image-link'/></Link>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite2.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite3.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div>
                                                <div className='mobile-designs-row'>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite4.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite5.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite6.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div><div className='mobile-designs-row'>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite7.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite8.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/EventsInvite/invite/invite9.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div>
                                            </div>
                        {/* <img src='/images/EventsDesign/Invites.jpg' alt='' className='menu-design-link-hero-image'/> */}
                </div>
            </div>
            </>
        )
    }
}
