import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";
import  Carousel from 'react-bootstrap/Carousel';


export default class CommunicationDesign extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Communication-Deisgn'}><img src='/images/CommunicationDesign/communication.svg' alt='communication-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-description py-4'>
                         The way a brand is communicated, influences its culture &
                         shapes values. At our concepts studio, we aim to provide
                         great designs for your brand, using communication tools 
                        to their complete potential.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Photoshoot'}>PHOTOSHOOT</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Graphics'}>GRAPHICS</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Video'}>video</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/Content'}>content</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Advertisement'}>advertisement</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Print'}>print</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                    <img src='/images/CommunicationDesign/communication-hero-image.png' alt='communication-hero-img' className='menu-designs-hero-image desktop-design-img'/>
                    <div className='mobile-design-carousel' >
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="menu-designs-carousel-image"
                                                src="/images/CommunicationDesign/content.jpg"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/CommunicationDesign/content.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="second slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/CommunicationDesign/content.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="third slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
