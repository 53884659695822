import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";
// import {IconContext} from 'react-icons';
// import * as VsIcon from 'react-icons/vsc';
// import Carousel from 'react-bootstrap/Carousel'


export default class Print extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Communication-Deisgn'}><img src='/images/CommunicationDesign/communication.svg' alt='communication-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-link-title pt-3' style={{}}>
                                    {/* <IconContext.Provider value={{className:"arrow-left menu-designs-left-arrow"}}>
                                                <Link to={'/CommunicationDesign'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider> */}
                                            PRINT
                                    </div>
                    <div className='menu-designs-description py-4'>
                                High quality printed objects to do justice to our 
                                high quality designs. This will establish an influential
                                 editorial style and add a luxurious tactility to your brand.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Photoshoot'}>PHOTOSHOOT</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Graphics'}>GRAPHICS</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Video'}>video</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/Content'}>content</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Advertisement'}>advertisement</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Print'}>print</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                    <img src='/images/CommunicationDesign/Print.jpg' alt='img' className='menu-design-link-hero-image'/>
                                        {/* <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="menu-designs-carousel-image"
                                                src="/images/CommunicationDesign/photoshoot-carousel-1.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/CommunicationDesign/photoshoot-carousel-1.png"
                                                className="menu-designs-carousel-image"
                                                alt="second slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/CommunicationDesign/photoshoot-carousel-1.png"
                                                className="menu-designs-carousel-image"
                                                alt="third slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel> */}
                </div>
            </div>
            </>
        )
    }
}
