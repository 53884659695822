import React, { Component } from 'react'
import './Carousels.css';
import * as VsIcon from 'react-icons/vsc';
import { IconContext } from 'react-icons'
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

export default class InviteOne extends Component {
    render() {
        return (
            <>
                <div className='web-pc-work-carousel'>
                                <div className='menu-designs-link-title pt-3 carousel-title'  style={{}}>
                                    <IconContext.Provider value={{className:"carousel-arrow-left "}}>
                                                <Link to={'/Invites'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider>Invites
                                </div>
                    <Carousel >
                            <Carousel.Item interval={1000}>
                                <img
                                    className="web-pc-work-wrap"
                                    src="/images/BrandDesign/brand-design-carousel-2.png"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={1000}>
                                <img
                                    className="web-pc-work-wrap"
                                    src="/images/BrandDesign/brand-design-carousel-2.png"
                                    alt="First slide"
                                />
                            </Carousel.Item><Carousel.Item interval={1000}>
                                <img
                                    className="web-pc-work-wrap"
                                    src="/images/BrandDesign/brand-design-carousel-2.png"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                                            
                    </Carousel>
                </div>
                <div className='mobile-pc-work-carousel'>
                                <div className='menu-designs-link-title pt-3 carousel-title'  style={{}}>
                                    <IconContext.Provider value={{className:"carousel-arrow-left "}}>
                                                <Link to={'/Invites'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider>Invites
                                </div>
                                <div className='invites-video-title'>Video</div>
                                <img src='/images/BrandDesign/brand-design-carousel-2.png' alt='..' className='Invite-video-control'/>
                            <div className='invites-video-title'>Print</div>
                            <Carousel>
                                <Carousel.Item interval={1000} className='jj'>
                                    <img
                                        className="mobile-pc-work-wrap"
                                        src="/images/BrandDesign/brand-design-carousel-2.png"
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                                <Carousel.Item interval={1000}>
                                    <img
                                        className="web-pc-work-wrap"
                                        src="/images/BrandDesign/brand-design-carousel-2.png"
                                        alt="First slide"
                                    />
                                </Carousel.Item><Carousel.Item interval={1000}>
                                    <img
                                        className="web-pc-work-wrap"
                                        src="/images/BrandDesign/brand-design-carousel-2.png"
                                        alt="First slide"
                                    />
                                </Carousel.Item>
                            </Carousel>
                </div>
            </>
        )
    }
}
