import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import AOS from 'aos';
import "aos/dist/aos.css";
import '../HeroSection/HeroSection.css';

export default class HomeScreenMenu extends Component {
    componentDidMount
    render() {
        
            AOS.init(
                {
                    duration: 1000
                }
            );
        return (
            
            <div className="page-container " style={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
                <div>
                    <div className='homescreen-services-block'>
                        <div data-aos=""><img src='/images/HomeScreenImages/Pencil.png' alt='pencil-img' className='pencil-image' /></div>
                        <div>
                            <div className='services-grid-container'>
                                <div className='services-grid-wrap mb-4'>
                                    <div  className='services-grid-image aos-animate' data-aos="" id='brnd'><Link to='/BrandDesign'><img src='/images/HomeScreenImages/branding.svg' alt='brand-design'/></Link></div>
                                    <div className='services-grid-image' id='Homescreen-Communication-Deisgn'><Link to={'/CommunicationDesign'}><img src='/images/HomeScreenImages/communication.svg' alt='brand-design'/></Link></div>
                                    <div className='services-grid-image'id='Homescreen-Digital-Deisgn'><Link to={'/DigitalDesign'}><img src='/images/HomeScreenImages/digital-marketing.svg' alt='brand-design'/></Link></div>
                                </div>
                                <div className='services-grid-wrap'>
                                    <div className='services-grid-image'id='Homescreen-Events-Deisgn'><Link to={'/EventsDesign'}><img src='/images/HomeScreenImages/events.svg' alt='brand-design'/></Link></div>
                                    <div className='services-grid-image'id='Homescreen-Ads-Deisgn'><Link to={'/AdsDesign'}><img src='/images/HomeScreenImages/ads.svg' alt='brand-design'/></Link></div>
                                    <div className='services-grid-image'id='Homescreen-Package-Design'><Link to={'/PackageDesign'}><img src='/images/HomeScreenImages/package.svg' alt='brand-design'/></Link></div>
                                </div>
                            </div>
                        </div>
                        <div><img src='/images/HomeScreenImages/decor.png' alt='decor-img' className='decor-image' /></div>
                    </div>
                </div>
                    <div className='mobile-services-block'>
                        <div className='mob-service-col '>
                        <Link to={'/BrandDesign'}><img src='/images/MobileHomeScreen/mob-brand.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        <Link to={'/DigitalDesign'}><img src='/images/MobileHomeScreen/mob-digital.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        <Link to={'/AdsDesign'}><img src='/images/MobileHomeScreen/mob-ads.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        </div>
                        <div className='mob-service-col'>
                        <Link to={'/CommunicationDesign'}><img src='/images/MobileHomeScreen/mob-communication.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        <Link to={'/#Homescreen-Events-Deisgn'}><img src='/images/MobileHomeScreen/mob-event.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        <Link to={'/PackageDesign'}><img src='/images/MobileHomeScreen/mob-package.svg' className='mobile-services-grid' alt='mob-brand'/></Link>
                        </div>
                        <div className=''>
                            <img src='/images/MobileHomeScreen/mob-decor.png' alt='mob-decor' className='mobile-decor'/>
                        </div>
                    </div>
            </div>
        )
    }
}
