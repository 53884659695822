import React, { Component } from 'react'
import {Container} from 'react-bootstrap'
import {IconContext} from 'react-icons'
import * as VsIcon from 'react-icons/vsc'
import {Link} from 'react-router-dom'
import './AboutUs.css';
import Aos from 'aos';
import Carousel from 'react-bootstrap/Carousel'
import Footer from '../../Components/Footer/Footer'
import Form from '../../Components/ContactForm/ContactForm'
import SocialMedia from '../../HomeScreen/SocialMediaIcons/SocialMediaIcons'



export default class AboutUs extends Component {
    componentDidMount() {
        // or simply just AOS.init();
        Aos.init({
          // initialise with other settings
          duration : 1000
        });
      }
    render()  {
        return (
            <>
                    <Container>
                        <div className='web-about-us'>
                            <div className=''> 
                                <div className='about-us-wrap about-us-container'>
                                    <div className='about-us-content abt-top' data-aos="fade-down">
                                        <div className='about-us-left-arrow'>
                                            <IconContext.Provider value={{className:"arrow-left"}}>
                                                <Link to={'/'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider>
                                        </div>
                                        <div className='about-us-text ml-5 pl-3'>
                                            <div className='about-us-title'>
                                                About us
                                            </div>
                                            Preciso Concepts is a multi-disciplinary design studio.
                                            We build unique identities and powerful designs for communication. 
                                            <br/>
                                            <br/>
                                            <span className='abt-cont'>We do it all, from conceptualising a brand’s identity, developing splendid
                                            events to promoting and establishing a powerful social media to drive impact
                                            and curate a vision for how an idea is communicated to the world.</span>
                                        </div>
                                    </div>
                                    <div className='about-us-carousel' data-aos="slide-left">
                                        <Carousel  >
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/abt-car-1.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/abt-car-1.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/abt-car-1.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                                <div className='about-us-wrap' >
                                    <div className='about-us-carousel' data-aos="slide-right">
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/s2@2x.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/s2@2x.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="carousel-image"
                                                src="/images/AboutUs/s2@2x.png"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    <div className='about-us-content vision-content' id ='vision'> 
                                        <div className='about-us-text ml-5 pl-3 about-vision-text' data-aos="fade-up">
                                            <div className='about-us-title'>
                                            Vision
                                            </div>
                                            To establish a unique space, for visionaries, artists and
                                             designers of various disciplines to create together and 
                                             give rise to a unique experience through conceptualisation.
                                             We aim to create a whole new world through precise design,
                                             which makes us a one stop solution for any new or existing brand.
                                        </div>
                                    </div>
                                </div>
                                <div className='team-container'>
                                    <div className='team-title about-us-title'>
                                        Team
                                    </div>
                                    <div className='team-content'>
                                        <div className='team-text about-us-text'>
                                            <div className='team-text-content'>
                                            Preciso studio is comprised of a multi-disciplinary
                                            group of passionate designers, who strive to help your vision come to life.
                                            </div>
                                                <img src='/images/AboutUs/about-team.svg' alt='team-img' className='team-content-image' />
                                        </div>
                                    </div>
                                </div>
                                <div className='premium-container'>
                                    <div className='premium-wrap'>
                                        <div className='premium-title'>
                                            <IconContext.Provider value={{className:"premium-right-arrow"}}>
                                                <Link to={'/'} >
                                                        <VsIcon.VscChevronRight />
                                                </Link> 
                                            </IconContext.Provider>Premium Clients
                                        </div>
                                        <div className='premium-clients-container mt-5 pt-3'>
                                            <div className='premium-clients-wrap'>
                                                <img src='/images/ClientsLogo/art.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/artnam.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/avo.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/frr.jpg' alt='atr-attire'className='premium-client-logo'/>
                                            </div>
                                            <div className='premium-clients-wrap'>
                                                <img src='/images/ClientsLogo/klite.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/kobelco.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/mf.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/mint.jpg' alt='atr-attire'className='premium-client-logo'/>
                                            </div>
                                            <div className='premium-clients-wrap'>
                                                <img src='/images/ClientsLogo/quarto.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/roling.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/s2capital.jpg' alt='atr-attire'className='premium-client-logo'/>
                                                <img src='/images/ClientsLogo/satnam.jpg' alt='atr-attire'className='premium-client-logo'/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='preciso-group-container'>
                                    <div className='preciso-group-wrap preciso-wrap'>
                                        <div className='preciso-concepts-part'>Preciso Concepts is a part of</div>
                                        <div className='preciso-group-title mt-5 mb-5'>Preciso Group</div>
                                        <div className='preciso-group-explore'>Explore</div>
                                        <div className='prciso-group-links mt-3'><span className='pr-5'>Preciso Fashion</span><span>Preciso Home</span></div>
                                    </div>
                                    <div className='preciso-wrap'><img src='/images/AboutUs/abt-car-1.png' alt='preciso-grp-img' className='preciso-group-image'/></div>
                                </div>
                            </div>
                        </div>
                        <div className='mobile-about-us'>
                            <div className='mobile-about-wrap'>
                                <div className='mobile-about-title'>
                                    <IconContext.Provider value={{className:"arrow-left mob-left-arrow"}}>
                                        <Link to={'/'} >
                                            <VsIcon.VscChevronLeft />
                                        </Link> 
                                    </IconContext.Provider>about us
                                </div>
                                <div className='mob-about-text'>
                                        Preciso Concepts is a multi-disciplinary design studio. 
                                        We build unique identities and powerful designs for communication. 
                                        <br/>
                                        <br/>
                                        We do it all, from conceptualising a brand’s identity, developing 
                                        splendid events to promoting and establishing a powerful social media 
                                        to drive impact and curate a vision for how an idea is communicated to the world.
                                </div>
                            </div>
                            <div className='mobile-vision-container'>
                                <div className='mobile-vision' id='visions'>
                                    <div className='mob-vision-title pb-3'>vision</div>
                                    <div className='mob-vision-text'>
                                            To establish a unique space, for visionaries, 
                                            artists and designers of various disciplines to create 
                                            together and give rise to a unique experience through 
                                            conceptualisation. We aim to create a whole new world 
                                            through precise design, which makes us a one stop solution f
                                            or any new or existing brand.
                                    </div>
                                </div>
                                <img src='/images/MobileAbout/mob-vision.png' alt='mob-vision' className='mob-vision-img'/>
                            </div>
                            <div className='mobile-vision mobile-team' style={{backgroundColor:"#F1F2F2",height:"700px"}}>
                                <div className='mob-vision-title pb-3'>team</div>
                                <div className='mob-vision-text '>
                                    Preciso studio is comprised of a multi-disciplinary 
                                    group of passionate designers, who strive to help your vision come to life.
                                </div>
                                <img src='/images/AboutUs/about-team.svg' alt='mob-vision' style={{marginTop:"-50px"}} className='mob-vision-img '/>
                            </div>
                            <div className='mob-grp'>
                                <div className='mob-vision-text pb-3'>Preciso Concepts <br/> is a part of</div>
                                <div className='mob-grp-title pb-4'>Preciso Group</div>
                                <div className='mob-grp-explore pb-3'>explore</div>
                                <div className='mob-grp-link'>Preciso Home<br/>Preciso Fashion</div>
                            </div>
                        </div>
                    </Container>
                    <SocialMedia/>
                    <Form/>
                    <Footer/>
            </>
            
        )
    }
}
