import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";


export default class DigitalDesign extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Digital-Deisgn'}><img src='/images/DigitalDesign/digitaldesign.svg' alt='communication-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-description py-4'>
                                Strategically designed digital experiences, platforms & tools for a lasting first impression.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Website'}>WEBSITE</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/SocialMedia'}>SOCIAL MEDIA</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Blogs'}>BLOGS</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/NewsLetter'}>NEWSLETTER</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/WhatsappSms'}>WHATSAPP/SMS</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Promotions'}>PROMOTIONS</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                    <img src='/images/DigitalDesign/SocialMedia.jpg' alt='digital-hero-img' className='menu-designs-hero-image'/>
                </div>
            </div>
            </>
        )
    }
}
