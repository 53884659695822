import React from 'react'
import { BrowserRouter, Route,Switch } from 'react-router-dom';
import AboutUs from './assets/AboutUs/AboutUs';
import BrandDesign from './assets/BrandDesign/BrandDesign';
import Header from './Components/Header/Header';
import Name from './assets/BrandDesign/Name';
import Identity from './assets/BrandDesign/Identity';
import VisualDesign from './assets/BrandDesign/VisualDesign';
import ReBranding from './assets/BrandDesign/ReBranding';
import Logo from './assets/BrandDesign/Logo';
import Story from './assets/BrandDesign/Story';

import CommunicationDesign from './assets/CommunicationDesign/CommunicationDesign';
import Photoshoot from './assets/CommunicationDesign/Photoshoot';
import Graphics from './assets/CommunicationDesign/Graphics';
import Video from './assets/CommunicationDesign/Video';
import Content from './assets/CommunicationDesign/Content';
import Advertisement from './assets/CommunicationDesign/Advertisement';
import Print from './assets/CommunicationDesign/Print';

import DigitalDesign from './assets/DigitalDesign/DigitalDesign';
import Website from './assets/DigitalDesign/Website';
import SocialMedia from './assets/DigitalDesign/SocialMedia';
import Blogs from './assets/DigitalDesign/Blogs';
import NewsLetter from './assets/DigitalDesign/NewsLetter';
import WhatsappSms from './assets/DigitalDesign/WhatsappSms';
import Promotions from './assets/DigitalDesign/Promotions';

import EventsDesign from './assets/EventsDesign/EventsDesign';
import Concepts from './assets/EventsDesign/Concepts';
import Design from './assets/EventsDesign/Design';
import Invites from './assets/EventsDesign/Invites';
import Planning from './assets/EventsDesign/Planning';
import ThreeD from './assets/EventsDesign/3D';
import InviteOne from './assets/EventsDesign/InvitesCarousel/InviteOne';

import AdsDesign from './assets/AdsDesign/AdsDesign';
import AdsPrint from './assets/AdsDesign/AdsPrint';
import Shoot from './assets/AdsDesign/Shoot';
import AdsVideo from './assets/AdsDesign/AdsVideo';
import Mood from './assets/AdsDesign/Mood';

import PackageDesign from './assets/PackageDesign/PackageDesign';
import PackageMaterial from './assets/PackageDesign/PackageMaterial';
import PackageConcepts from './assets/PackageDesign/PackageConcepts';
import PackageGraphics from './assets/PackageDesign/PackageGraphics';
import PackageLabel from './assets/PackageDesign/PackageLabel';
import PackagePrint from './assets/PackageDesign/PackagePrint';
import ScrollToTop from './assets/ScrollToTop/ScrollToTop';
import Project from './assets/Project/Project';
import ClientOne from './assets/CommunicationDesign/GraphicCarousel/ClientOne';
import HomeScreen from './HomeScreen/HomeScreen';



function App() {
  return (
    <>
      <BrowserRouter> 
        <Header />
        <ScrollToTop/>
        <Switch>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/AboutUs/' render={ () => (<AboutUs />) }/>
          {/* BRAND DESIGN */}
          <Route path='/BrandDesign/' render={ () => (<BrandDesign />) }/>
          <Route path='/Name/' render={ () => (<Name />) }/>
          <Route path='/Identity/' render={ () => (<Identity />) }/>
          <Route path='/ReBranding/' render={ () => (<ReBranding />) }/>
          <Route path='/VisualDesign/' render={ () => (<VisualDesign />) }/>
          <Route path='/Logo/' render={ () => (<Logo />) }/>
          <Route path='/Story/' render={ () => (<Story />) }/>
          {/* COMMUNICATION DESIGn */}
          <Route path='/CommunicationDesign/' render={ () => (<CommunicationDesign />) }/>
          <Route path='/Photoshoot' render={ () => (<Photoshoot />) }/>
          <Route path='/Graphics' render={() => (<Graphics/>)}/>
          <Route path='/Video' render={() => (<Video />)}/>
          <Route path='/Content' render={() => (<Content />)}/>
          <Route path='/Advertisement' render={() => (<Advertisement />)}/>
          <Route path='/Print' render={() => (<Print />)}/>
          <Route path='/ClientOne' render={() => (<ClientOne />)}/>

          {/* DIGITALDESIGN */}
          <Route path='/DigitalDesign/' render={ () => (<DigitalDesign />) }/>
          <Route path='/Website' render={ () => (<Website />) }/>
          <Route path='/SocialMedia' render={() => (<SocialMedia/>)}/>
          <Route path='/Blogs' render={() => (<Blogs />)}/>
          <Route path='/NewsLetter' render={() => (<NewsLetter />)}/>
          <Route path='/WhatsappSms' render={() => (<WhatsappSms />)}/>
          <Route path='/Promotions' render={() => (<Promotions />)}/>
          {/* EVENTS DESIGN */}
          <Route path='/EventsDesign' render={() => (<EventsDesign />)}/>
          <Route path='/Concepts' render={() =>(<Concepts />)}/>
          <Route path='/Design' render={() =>(<Design />)}/>
          <Route path='/Invites' render={() =>(<Invites />)}/>
          <Route path='/Planning' render={() =>(<Planning />)}/>
          <Route path='/3D' render={() =>(<ThreeD />)}/>
          
          <Route path='/InviteOne' render={() => (<InviteOne/>)}/>
          {/* ADS DESIGN */}
          <Route path='/AdsDesign' render={() => (<AdsDesign/>)}/>
          <Route path='/AdsPrint' render={() => (<AdsPrint/>)}/>
          <Route path='/Shoot' render={() => (<Shoot/>)}/>
          <Route path='/AdsVideo' render={() => (<AdsVideo />)}/>
          <Route path='/Mood' render={() => (<Mood />)}/>
          {/* PACKAGE DESIGN */}
          <Route path='/PackageDesign' render={() => (<PackageDesign />)}/>
          <Route path='/PackageMaterial' render={() => (<PackageMaterial />)}/>
          <Route path='/PackageConcepts' render={() => (<PackageConcepts />)}/>
          <Route path='/PackageGraphics' render={() => (<PackageGraphics/>)}/>
          <Route path='/PackageLabel' render={() =>(<PackageLabel/>)}/>
          <Route path='/PackagePrint' render={() => (<PackagePrint/>)}/>

          <Route path='/Project' render={() => (<Project/>)}/>
 
        </Switch>
        
      </BrowserRouter>
    </>
  );
}

export default App;
