import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import Aos from 'aos';
import "aos/dist/aos.css";
import './HeroSection.css';

export default class HeroSection extends Component {
    componentDidMount() {
        // or simply just AOS.init();
        Aos.init({
          // initialise with other settings
          duration : 1000
        });
      }
      
    render() {
        return (
            <>
                <Container >
                    <div className="page-container" >
                        <div >
                    <div className='hero-image-container'>
                            <div className='hero-image-slide slide-one' data-aos="slide-right"><img src='/images/HomeScreenImages/slide-left.jpg' alt='' /></div>
                            <div className='hero-image-slide slide-two' data-aos="slide-left"><img src='/images/HomeScreenImages/slide-right.jpg' alt='' /></div>
                    </div>
                    </div>
                    <div className='mobile-hero-image'><img src='/images/MobileHomeScreen/mobile-front-banner-01.jpg' alt='mob-hero' className='mobile-hero-img'/></div>
                    
                    </div>
                </Container>
            </>
        )
    }
}
