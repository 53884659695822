import React, { Component } from 'react'
import ReactPageScroller from 'react-page-scroller';
import ContactForm from '../Components/ContactForm/ContactForm';
import Footer from '../Components/Footer/Footer';
import HeroSection from './HeroSection/HeroSection';
import HomeScreenAbout from './HomeScreenAbout/HomeScreenAbout';
import HomeScreenMenu from './HomeScreenMenu/HomeScreenMenu';
import HomeScreenProject from './HomeScreenProject/HomeScreenProject';
import SocialMediaIcons from './SocialMediaIcons/SocialMediaIcons';
import AOS from 'aos';
import "aos/dist/aos.css";

export default class HomeScreen extends Component {
    render() {
        AOS.refresh(
            {
                duration: 1000
            }
        );
        return (
            <>
                <ReactPageScroller>
                    <HeroSection/>
                    <HomeScreenMenu />
                    <HomeScreenAbout/>
                    <HomeScreenProject/>
                    <SocialMediaIcons/>
                    <ContactForm/>
                    <Footer/>
                </ReactPageScroller>  
            </>
        )
    }
}
