import React, { Component } from 'react'
import * as VsIcon from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons'
import './Project.css';
 
export default class Project extends Component {
    render() {
        return (
            <>
                <div className='web-project-container'></div>
                <div className='mobile-project-wrap'>
                    <div className='mobile-about-title mb-3' st>
                    <IconContext.Provider value={{className:"arrow-left mob-left-arrow"}}>
                        <Link to={'/'} >
                            <VsIcon.VscChevronLeft />
                        </Link> 
                    </IconContext.Provider>projects
                    </div>
                    <div className='mobile-project-container' style={{display:"flex"}}>
                                    
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <img src='/images/ClientsLogo/art.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/artnam.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/avo.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/frr.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/quarto.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/roling.jpg' className='mob-project-img' alt='art-attire'/>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <img src='/images/ClientsLogo/klite.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/kobelco.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/mf.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/s2capital.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/satnam.jpg' className='mob-project-img' alt='art-attire'/>
                            <img src='/images/ClientsLogo/mint.jpg' className='mob-project-img' alt='art-attire'/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
