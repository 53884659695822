import React, { Component } from 'react'
import {Container} from 'react-bootstrap'
import './HomeScreenProject.css';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

export default class HomeScreenProject extends Component {
    render() {
        return (
            <>
                <Container>
                    <div className='homescreen-project-container page-container'>
                        <>
                            <div className='homescreen-project-container-one'>
                                <div className='homescreen-project-wrap '>
                                    <div className='homescreen-project-grid'><img src='/images/HomeScreenImages/project.svg' alt='cap-p' className='' /></div>
                                    <div className='homescreen-project-grid '><img src='/images/HomeScreenImages/p.svg' alt='cap-p' className='' /></div>
                                </div>
                                <div className='homescreen-project-text my-5'>
                                    Preciso Concepts works with a plethora of individuals,
                                    brands, businesses & organisations. Our studio creates books, weddings, 
                                    events, digital experiences and content. We have done it all ! Our unique 
                                    approach and commitment to precise and impactful design is what sets us apart.
                                </div>
                            </div>
                        </>
                        <div className='homescreen-project-container-two'> 
                            <div className='project-image'>
                            <img src='/images/HomeScreenImages/project.jpg' alt='project-img' className='project-image'/>
                            </div>
                        </div>
                    </div>
                    <div className="page-container" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                        <div className='mobile-about-container'>
                            <div className='mobile-about-row px-4'>
                            <Link to={'/Project'}>
                            <img src='/images/HomeScreenImages/p.svg' alt='abt-about' className='mobile-about-grid'/>
                            <img src='/images/HomeScreenImages/project.svg' alt='abt-about' className='mobile-about-grid ml-2'/>
                            </Link>
                            </div>
                            <div className='mobile-about-text px-4 py-3'>
                                    Preciso Concepts works with a plethora of 
                                    individuals, brands, businesses & organisations. 
                                    Our studio creates books, weddings, events, digital 
                                    experiences and content. We have done it all ! Our unique 
                                    approach and commitment to precise and impactful design is what sets us apart.
                            </div>
                            <Carousel>
                                                    <Carousel.Item interval={1000}>
                                                        <img
                                                        className="mobile-home-project-carousel"
                                                        src="/images/HomeScreenImages/project.jpg"
                                                        alt="First slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item interval={1000}>
                                                        <img
                                                        src="/images/BrandDesign/brand-design-carousel-2.png"
                                                        className="mobile-home-project-carousel"
                                                        alt="second slide"
                                                        />
                                                    </Carousel.Item>
                                                    <Carousel.Item interval={1000}>
                                                        <img
                                                        src="/images/BrandDesign/brand-design-carousel-3.png"
                                                        className="mobile-home-project-carousel"
                                                        alt="third slide"
                                                        />
                                                    </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}
