import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";
// import {IconContext} from 'react-icons';
// import * as VsIcon from 'react-icons/vsc';
import Carousel from 'react-bootstrap/Carousel';
// import { Route, BrowserRouter, Switch } from 'react-router-dom';
// import ClientOne from './GraphicCarousel/ClientOne';


export default class Graphics extends Component {
    render() {
        return (
            <>
            {/* <BrowserRouter> */}
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Communication-Deisgn'}><img src='/images/CommunicationDesign/communication.svg' alt='communication-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                                <div className='menu-designs-link-title pt-3' style={{}}>
                                    {/* <IconContext.Provider value={{className:"arrow-left menu-designs-left-arrow"}}>
                                                <Link to={'/CommunicationDesign'} >
                                                        <VsIcon.VscChevronLeft />
                                                </Link> 
                                            </IconContext.Provider> */}
                                            GRAPHICS
                                </div>
                    <div className='menu-designs-description py-4'>
                            In today’s post digital age, a strong and personalised 
                            online presence is essential. Our passionate team of
                            designers compose extraordinary graphics,
                            typography and illustrations.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Photoshoot'}>PHOTOSHOOT</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Graphics'}>GRAPHICS</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Video'}>video</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/Content'}>content</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Advertisement'}>advertisement</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Print'}>print</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                                        <>
                                            <div className='mobile-design-carousel'>
                                                <div className='mobile-designs-row'>
                                                    <Link to='/ClientOne'><img src='/images/CommunicationDesign/graphic.png' alt='' className='mobile-menu-designs-grid-image-link'/></Link>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic2.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic3.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div>
                                                <div className='mobile-designs-row'>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic4.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic5.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic6.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div><div className='mobile-designs-row'>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic7.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic8.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                    <a href='/Graphics/'><img src='/images/CommunicationDesign/graphic9.png' alt='' className='mobile-menu-designs-grid-image-link'/></a>
                                                </div>
                                            </div>
                                            <div className='desktop-design-carousel'>
                                                <Carousel>
                                                    <Carousel.Item interval={1000}>
                                                        <div className='menu-designs-grid-carousel'>
                                                            <Link to='/ClientOne'><img src='/images/CommunicationDesign/graphic.png' alt='' className='menu-designs-grid-image-link'/></Link>
                                                            <img src='/images/CommunicationDesign/graphic2.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic3.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>
                                                        <div className='menu-designs-grid-carousel'>
                                                            <img src='/images/CommunicationDesign/graphic4.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic5.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic6.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>                                                <div className='menu-designs-grid-carousel'>
                                                            <img src='/images/CommunicationDesign/graphic7.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic8.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic9.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>
                                                    </Carousel.Item>
                                                    <Carousel.Item interval={1000}>
                                                    <div className='menu-designs-grid-carousel'>
                                                            <Link to='/ClientOne'><img src='/images/CommunicationDesign/graphic.png' alt='' className='menu-designs-grid-image-link'/></Link>
                                                            <img src='/images/CommunicationDesign/graphic2.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic3.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>
                                                        <div className='menu-designs-grid-carousel'>
                                                            <img src='/images/CommunicationDesign/graphic4.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic5.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic6.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>                                                <div className='menu-designs-grid-carousel'>
                                                            <img src='/images/CommunicationDesign/graphic7.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic8.png' alt='' className='menu-designs-grid-image-link'/>
                                                            <img src='/images/CommunicationDesign/graphic9.png' alt='' className='menu-designs-grid-image-link'/>
                                                        </div>
                                                    </Carousel.Item>
                                            </Carousel>
                                            </div>
                                        </>

                    {/* <div className='menu-designs-grid-carousel'>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-2.png' alt='' className='menu-designs-grid-image-link'/>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                    </div>
                    <div className='menu-designs-grid-carousel'>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-2.png' alt='' className='menu-designs-grid-image-link'/>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                    </div><div className='menu-designs-grid-carousel'>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                        <img src='/images/CommunicationDesign/menu-grid-to-carousel-1.png' alt='' className='menu-designs-grid-image-link'/>
                    </div> */}
                        {/* <img src='/images/CommunicationDesign/Graphic.jpg' alt='' className='menu-design-link-hero-image'/> */}
                </div>
            </div>
            {/* <Switch>
                <Route path='/ClientOne'>
                    <ClientOne/>
                </Route>
            </Switch>
            </BrowserRouter> */}
            </>
            
        )
    }
}