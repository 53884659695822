import React, { Component } from 'react'
import { Container } from 'react-bootstrap';
import * as FaIcons from "react-icons/fa";
import './Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <>
                <Container>
                    <div className="page-container web-footer" style={{justifyContent:"center",flexDirection:"column",display:"flex"}}>
                        {/* <div className='footer-container'>
                            <div className='footer-grid-container' >
                                <Col>
                                    <div className='footer-wrap'>
                                    <div className='footer-grid'>
                                            <div className='footer-grid-title'>COMPANY</div>
                                                <ul className='footer-item'>
                                                    <li><a href='/AboutUs/#vision'>Vision</a></li>
                                                    <li><a href={'/AboutUs/'}>About Us</a></li>
                                                    <li>Blog</li>
                                                    <li>Career</li>
                                                    <li>Contact</li>
                                                </ul>
                                        </div>
                                        <div className='footer-grid'>
                                            <div className='footer-grid-title'>SERVICES</div>
                                                <ul className='footer-item'>
                                                    <li><a href={'/BrandDesign'}>Brand Design</a></li>
                                                    <li><a href={'/CommunicationDesign'}>Communication</a></li>
                                                    <li><a href={'/DigitalDesign'}>Digital</a></li>
                                                    <li><a href={'/EventsDesign'}>Events</a></li>
                                                    <li><a href={'AdsDesign'}>ADS</a></li>
                                                    <li><a href={'PackageDesign'}>packaging</a></li>
                                                </ul>
                                        </div>
                                    </div><div className='footer-wrap'>
                                    <div className='footer-grid address-grid'>
                                            <div className='footer-grid-title'style={{textTransform:"capitalize"}}>Address</div>
                                                <ul className='footer-item'>
                                                    <li>preciso concepts</li>
                                                    <li>3A, LVR Centre,</li>
                                                    <li>7 Seshadri road</li>
                                                    <li>Alwarpet,</li>
                                                    <li>Chennai-600018</li>
                                                </ul>
                                        </div>
                                        <div className='footer-grid'>
                                            <div className='footer-grid-title' style={{textTransform:"capitalize"}}>ContactUs</div>
                                                <ul className='footer-item'>
                                                    <li>044 45030020 </li>
                                                    <li>+91 9040475000</li>
                                                    <li>info@precisoconcepts.com</li>
                                                </ul>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div>
                                <Col>
                                    <div className='footer-image-block'>
                                    <img src='/images/HomeScreenImages/footer-image.png' className='' alt='footer-img' />
                                    </div>
                                </Col>
                            </div>
                        </div> */}
                                    <div className="footer-row" style={{display:"flex"}}>
                                        <div className='footer-grid'>
                                            <div className='footer-grid-title'>COMPANY</div>
                                                <ul className='footer-item'>
                                                    <li><a href='/AboutUs/#vision'>Vision</a></li>
                                                    <li><a href={'/AboutUs/'}>About Us</a></li>
                                                    <li>Blog</li>
                                                    <li>Career</li>
                                                    <li>Contact</li>
                                                </ul>
                                        </div>
                                        <div className='footer-grid'>
                                            <div className='footer-grid-title'>SERVICES</div>
                                                <ul className='footer-item'>
                                                    <li><a href={'/BrandDesign'}>Brand Design</a></li>
                                                    <li><a href={'/CommunicationDesign'}>Communication</a></li>
                                                    <li><a href={'/DigitalDesign'}>Digital</a></li>
                                                    <li><a href={'/EventsDesign'}>Events</a></li>
                                                    <li><a href={'AdsDesign'}>ADS</a></li>
                                                    <li><a href={'PackageDesign'}>packaging</a></li>
                                                </ul>
                                        </div>
                                        <div className='footer-image'>
                                            <img src='/images/HomeScreenImages/footer-image.png' className='ft-img' alt='footer-img' />
                                        </div>
                                    </div>
                                    <div className='ff'>
                            <div className="footer-two" style={{display:"flex",justifyContent:"center"}}>
                                <div className='footer-row-two' style={{textAlign:"left"}}><div className="address-title">ADDRESS</div>
                                    Preciso Concepts 3A, LVR Centre, 7 Seshadri Road<br/> Alwarpet, Chennai - 600018
                                    <div className='footer-tagline g'>
                                        &#169;All Rights Reserved - Preciso Concepts 2020 | Designed by - Preciso Concepts
                                    </div> 
                                </div>
                                <div className="footer-row-two footer-contact"><div className="address-title">CONTACT</div> 044 45030020, +91 9040475000<div>info@precisoconcepts.com</div></div>
                            </div>
                            </div>
                        </div>
                </Container>
                <Container>
                    <div className="page-container pg" style={{display:"flex",justifyContent:"flex-end",flexDirection:"column"}}>
                    <div className='mobile-social-media-icon' id='FollowuS'>
                                <div className='mobile-social-title'>follow us</div>
                                <a href='https://www.instagram.com/precisoconcepts/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaInstagram /></div></a>
                                <a href='https://www.facebook.com/brandconceptsdigitalmarketingdesignstudio/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#ADCD93"}}><FaIcons.FaFacebookF /></div></a>
                                <a href='https://wa.me/919040475000' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#8AA86E"}}><FaIcons.FaWhatsapp /></div></a>
                                <a href='https://twitter.com/PRECISOCONCEPT4' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaTwitter /></div></a>
                                <a href='https://www.linkedin.com/company/14535934/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#748360"}}><FaIcons.FaLinkedinIn /></div></a>
                                <a href='https://www.pinterest.co.uk/precisoconcepts/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaPinterestP /></div></a>
                                <a href='https://www.youtube.com/channel/UC1KIqtoOe5hzJnswK-7q6Mg' target='blank'><div className='mob-social-icon mb-1' style={{backgroundColor:"#8AA86E"}}><FaIcons.FaYoutube /></div></a>
                            </div>
                <div className='mobile-footer'>
                            <div className='mobile-footer-wrap '>
                                <div className='mobile-footer-links '>
                                    <div className=''>our company</div>
                                    <br/>
                                    <a href='/AboutUs/#visions'>vision</a>
                                    <br/>
                                    <a href='/AboutUs'>about us</a>
                                    <br/>
                                    <a href='/Blogs'>blog</a>
                                    <br/>
                                    Career
                                    <br/>
                                    <a href='#mobileForm'>contact</a>
                                </div>
                                <div className='mobile-footer-links '>
                                    <div className=''>service</div>
                                    <br/>
                                    <a href='/BrandDesign'>brand</a>
                                    <br/>
                                    <a href='/CommunicationDesign'>communication</a>
                                    <br/>
                                    <a href='/DigitalDesign'>digital</a>
                                    <br/>
                                    <a href='/EventsDesign'>events</a>
                                    <br/>
                                    <a href='/AdsDesign'>ads</a>
                                    <br/>
                                    <a href='/PackageDesign'>packaging</a>
                                </div>
                                <div className='mobile-footer-links '>
                                    <div className='' >legal</div>
                                    <br/>
                                    Privacy policy
                                    <br/>
                                    Agreement
                                    <br/>
                                    blog
                                    <br/>
                                    terms of use
                                </div>
                            </div>
                            <div style={{textAlign:"center",color:"#D3D3D3",borderTop:"1px solid #D3D3D3",padding:"5px 0px",opacity:"0.4",textTransform:"capitalize"}} >Designed by PrecisoConcepts</div>
                        </div>
                        </div>
                </Container>

            </>
        )
    }
}
