import React, { Component } from 'react'
import './HomeScreenAbout.css';
import {Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class HomeScreenAbout extends Component {
    render() {
        return (
            <>
                <Container >
                    <div className='homescreen-about-container'>
                        <div className="page-container" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            <div style={{display:"flex"}}>
                                <div className='homescreen-about-grid-wrap'>
                                    <div className='homescreen-about-grid'><Link to={'/AboutUs'}><img src='/images/HomeScreenImages/about.svg'  alt='about-img'/></Link></div>
                                    <div className='homescreen-about-grid'><img src='/images/HomeScreenImages/p.svg'  alt='cap-p-img'/></div>
                                </div>
                                <div>
                                    <div className='homescreen-about-title'>Preciso Concepts is a multi-disciplinary design studio.</div>
                                    <div className='homescreen-about-text'>
                                        Preciso Concepts is a multi-disciplinary design studio.<br/>
                                        We build unique identities and powerful designs for<br/> communication.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Container>
                <Container>
                    <div className="page-container" style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                        <div className='mobile-about-container'>
                            <div className='mobile-about-row px-4'>
                            <Link to={'/AboutUs'}><img src='/images/HomeScreenImages/about.svg' alt='abt-about' className='mobile-about-grid mr-2'/>
                            <img src='/images/HomeScreenImages/p.svg' alt='abt-about' className='mobile-about-grid'/></Link>
                            </div>
                            <div className='mobile-about-text'>
                                    Preciso Concepts is a multi - disciplinary
                                    design studio. We build unique identities and
                                    powerful designs for communication.
                            </div>
                            <img src='/images/MobileHomeScreen/sm-service-02.png' alt='abt-home-hero' className='mobile-about-hero'/>
                        </div>
                    </div>
                </Container>

            </>
        )
    }
}
