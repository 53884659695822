import React, { Component } from 'react'
import './SocialMediaIcons.css'
import * as FaIcons from "react-icons/fa";
import { Container} from 'react-bootstrap';


export default class SocialMediaIcons extends Component {
    render() {
        return (
            <>
                    <Container>
                        <div className='page-container abc' style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            <div className='social-media-icons-container ' id=''>
                                <div className='social-media-title'>follow  us here</div>
                                <div className='social-media-container'>
                                    <div className="social-media-icons-wrap">
                                        <a href='https://www.facebook.com/brandconceptsdigitalmarketingdesignstudio/' target='blank'><div className="facebook social-media-icons"> <FaIcons.FaFacebookF /></div></a>
                                        <a href='https://www.instagram.com/precisoconcepts/' target='blank'><div className="instagram social-media-icons"><FaIcons.FaInstagram/></div></a>
                                        <a href='https://twitter.com/PRECISOCONCEPT4' target='blank'><div className="twitter social-media-icons"><FaIcons.FaTwitter/></div></a>
                                        <a href='https://www.pinterest.co.uk/precisoconcepts/' target='blank'><div className="pinterest social-media-icons"><FaIcons.FaPinterestP/></div></a>
                                        <a href='https://wa.me/919040475000' target='blank'><div className="whatsapp social-media-icons"><FaIcons.FaWhatsapp/></div></a>
                                        <a href='https://www.linkedin.com/company/14535934/' target='blank'><div className="linkedin social-media-icons"><FaIcons.FaLinkedinIn/></div></a>
                                        <a href='https://www.youtube.com/channel/UC1KIqtoOe5hzJnswK-7q6Mg' target='blank'><div className="youtube social-media-icons"><FaIcons.FaYoutube/></div></a>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </Container>
                     <Container>
                        <div className="page-container pg" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            <img src='/images/MobileHomeScreen/contact-mob.png' alt='cont-det' className='mobile-contact-detail-img'/>

                            {/* <div className='mobile-social-media-icon' id='FollowuS'>
                                <div className='mobile-social-title'>follow us</div>
                                <a href='https://www.instagram.com/precisoconcepts/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaInstagram /></div></a>
                                <a href='https://www.facebook.com/brandconceptsdigitalmarketingdesignstudio/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#ADCD93"}}><FaIcons.FaFacebookF /></div></a>
                                <a href='https://wa.me/919040475000' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#8AA86E"}}><FaIcons.FaWhatsapp /></div></a>
                                <a href='https://twitter.com/PRECISOCONCEPT4' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaTwitter /></div></a>
                                <a href='https://www.linkedin.com/company/14535934/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#748360"}}><FaIcons.FaLinkedinIn /></div></a>
                                <a href='https://www.pinterest.co.uk/precisoconcepts/' target='blank'><div className='mob-social-icon' style={{backgroundColor:"#C5DCAD"}}><FaIcons.FaPinterestP /></div></a>
                                <a href='https://www.youtube.com/channel/UC1KIqtoOe5hzJnswK-7q6Mg' target='blank'><div className='mob-social-icon mb-1' style={{backgroundColor:"#8AA86E"}}><FaIcons.FaYoutube /></div></a>
                            </div> */}
                        </div>
                    </Container> 
            </>
        )
    }
}
