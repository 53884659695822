import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";
import Carousel from 'react-bootstrap/Carousel';


export default class PackageDesign extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Package-Design'}><img src='/images/PackageDesign/package.svg' alt='events-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-description py-4'>
                            Let’s showcase your products in different 
                            concepts, style and unique way to whole world 
                            through our designed ADS.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/PackageConcepts'}>concepts</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/PackageMaterial'}> material</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/PackageGraphics'}> graphics</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/PackageLabel'}>label</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/PackagePrint'}>print</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                    {/* <img src='/images/EventsDesign/SocialMedia.jpg' alt='digital-hero-img' className='menu-designs-hero-image'/> */}
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="menu-designs-carousel-image"
                                                src="/images/PackageDesign/Concepts-2.jpg"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/PackageDesign/Concepts-2.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="second slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/PackageDesign/Concepts-2.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="third slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                </div>
            </div>
            </>
        )
    }
}
