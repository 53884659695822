import React, { Component } from 'react'
import './MenuDesigns.css';
import { HashLink as Link } from "react-router-hash-link";
import Carousel from 'react-bootstrap/Carousel';


export default class EventsDesign extends Component {
    render() {
        return (
            <>
            <div className='menu-designs-container'>
                <div className='menu-designs-wrap menu-designs-col-one'>
                    <div className='menu-designs-grid'>
                        <Link to={'/#Homescreen-Events-Deisgn'}><img src='/images/EventsDesign/events.svg' alt='events-grid' className='menu-grid-image mr-3'/></Link>
                        <div className='menu-grid-image' style={{border:"1px solid #CFD0CF",borderRadius: "6px"}}/>
                    </div>
                    <div className='menu-designs-description py-4'>
                                Strategically designed digital experiences, platforms & tools for a lasting first impression.
                    </div>
                    <div className='menu-designs-link-wrap'>
                        <div className='menu-designs-link menu-sublink-divider pr-3' style={{textAlign:"right"}}>
                            <span className='menu-designs-sublink'><Link to={'/Concepts'}>CONCEPTS</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Invites'}> INVITES</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/Design'}>design</Link></span><br/>
                        </div>
                        <div className='menu-designs-link pl-3' style={{textAlign:"left"}}>
                            <span className='menu-designs-sublink'><Link to={'/Planning'}>planning</Link></span><br/>
                            <span className='menu-designs-sublink'><Link to={'/3D'}>3D</Link></span><br/>
                        </div>
                    </div>
                </div>
                <div className='menu-designs-wrap menu-designs-col-two'>
                    {/* <img src='/images/EventsDesign/SocialMedia.jpg' alt='digital-hero-img' className='menu-designs-hero-image'/> */}
                                        <Carousel>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                className="menu-designs-carousel-image"
                                                src="/images/EventsDesign/Planning.jpg"
                                                alt="First slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/EventsDesign/Planning.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="second slide"
                                                />
                                            </Carousel.Item>
                                            <Carousel.Item interval={1000}>
                                                <img
                                                src="/images/EventsDesign/Planning.jpg"
                                                className="menu-designs-carousel-image"
                                                alt="third slide"
                                                />
                                            </Carousel.Item>
                                        </Carousel>
                </div>
            </div>
            </>
        )
    }
}
